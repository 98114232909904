const GlobalConstants = {
    // Local System
       homeUrl: "https://docchoice.com/mokasoftwares",
    baseUrl: "https://healthhub.docchoice.com/",
    // api_patient: "http://localhost:3000",

    // Test System
    //   homeUrl: "https://docchoice.com/mokasoftwares",
    // baseUrl: "http://healthhub.docchoice.com:8060/",
    // api_patient: "http://healthhub.docchoice.com:8060/",

    // Production System
    // homeUrl: "www.mokasoftwares.com",
    // api_patient: "https://docchoice.com",
    // api_patient: "http://25.52.83.167:8080",
    // baseUrl: "https://docchoice.com",
    // homeUrl: "https://docchoice.com/mokasoftwares",
}
export default GlobalConstants;