import "./App.css";
import React, { lazy, Suspense } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Fallback from "../src/Components/Fallback/Fallback";
import NavBar from "../src/Components/NavBar";
import "react-notifications/lib/notifications.css";

const HomePage = lazy(() => import("../src/pages/HomePage"));
const HealthBubbleScreen = lazy(() =>
  import("../src/pages/HealthBubbleScreen")
);
const PatientEngagementScreen = lazy(() =>
  import("../src/pages/PatientEngagementScreen")
);
const ReinventScreen = lazy(() => import("../src/pages/ReinventScreen"));
const ClinicSolutions = lazy(() => import("../src/pages/ClinicSolutions"));
const HospitalSolutions = lazy(() => import("../src/pages/HospitalSolutions"));
const PharmacySolutions = lazy(() => import("../src/pages/PharmacySolutions"));
const LaboratorySolutions = lazy(() => import("../src/pages/LaboratorySolutions"));
const FinanceSolutions = lazy(() => import("../src/pages/FinanceSolutions"));
const ECommerceSolutions = lazy(() => import("../src/pages/ECommerceSolutions"));
const AboutUs = lazy(() => import("../src/pages/AboutUs"));
const Comingsoon = lazy(() => import("../src/pages/Comingsoon.js"));
const Ourteam = lazy(() => import("../src/pages/Ourteam"));
const ERPServices = lazy(() => import("../src/pages/ERPServices"));
const ContactUs = lazy(() => import("../src/pages/ContactUs"));
// const LaboratorySolutions = lazy(() => import("../src/pages/LaboratorySolutions"));
// const LaboratorySolutions = lazy(() => import("../src/pages/LaboratorySolutions"));
const RequestDemo = lazy(() => import("../src/pages/RequestDemo"));

const ima = lazy(() => import("../src/pages/ima"));
// const Footer = lazy(() => import("../src/pages/Footer"));
function App() {
  return (
    <>
      <React.StrictMode>
        <Suspense fallback={<Fallback />}>
          <HashRouter basename="docchoice">
            <NavBar></NavBar>
            <Switch>
              <Route exact path="/" >
                <Redirect to="/HomePage" />
              </Route>
              <Route exact path="/HomePage" component={HomePage} />
              <Route
                exact
                path="/HealthBubbleScreen"
                component={HealthBubbleScreen}
              />
              <Route
                exact
                path="/PatientEngagementScreen"
                component={PatientEngagementScreen}
              />
              <Route exact path="/ReinventScreen" component={ReinventScreen} />
              <Route
                exact
                path="/ClinicSolutions"
                component={ClinicSolutions}
              />
              <Route
                exact
                path="/HospitalSolutions"
                component={HospitalSolutions}
              />
              <Route
                exact
                path="/PharmacySolutions"
                component={PharmacySolutions}
              />
              <Route
                exact
                path="/LaboratorySolutions"
                component={LaboratorySolutions}
              />

              <Route
                exact
                path="/FinanceSolutions"
                component={FinanceSolutions}
              />
              <Route
                exact
                path="/ECommerceSolutions"
                component={ECommerceSolutions}
              />

              <Route
                exact
                path="/AboutUs"
                component={AboutUs}
              />

              <Route
                exact
                path="/Comingsoon"
                component={Comingsoon}
              />
              <Route
                exact
                path="/Ourteam"
                component={Ourteam}
              />
              <Route
                exact
                path="/ERPServices"
                component={ERPServices}
              />

              <Route
                exact
                path="/ContactUs"
                component={ContactUs}
              />

              <Route exact path="/RequestDemo" component={RequestDemo} />
              <Route exact path="/ima" component={ima} />
              <HomePage></HomePage>
              <RequestDemo></RequestDemo>
              {/* <Footer></Footer> */}
            </Switch>
          </HashRouter>
        </Suspense>
      </React.StrictMode>
    </>
  );
}

export default App;
