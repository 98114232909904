import React from "react";
import "./fallback.scss";

const Fallback = () => {
  return (
    <div className="fallback-container">
      {[1, 2, 3].map((el) => (
        <div className="fallback" key={el}>
          <div className="header-block">
            <div className="circle-img loads"></div>
            <div className="header-content">
              <div className="content-shape loads"></div>
              <div className="content-shape loads"></div>
            </div>
          </div>
          <div className="image-placeholder loads"></div>
          <div className="footer-block">
            <div className="content-shape loads"></div>
            <div className="content-shape loads"></div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Fallback;
