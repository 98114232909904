import React from 'react'
import { withRouter } from 'react-router-dom'
// import Navbar from "react-bootstrap/Navbar";
// import { Nav, NavDropdown } from "react-bootstrap";
import CompanyLogo from '../Images/company_logo.png'
import Constants from '../GlobalConstants'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'

import '../css/NavBar.css'

class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }
  navigateTo = (link) => {
    this.props.history.push(link)
  }
  navigateToHome = () => {
    this.props.history.push('/')
  }
  render() {
    return (
      <>
        {/* <div className="navBarPosition" >
          <Navbar collapseOnSelect
            bg="light"
            expand="lg"
            className="justify-content-center"
            style={{ paddingLeft: 20 }}
          >
            <Container>
              <Navbar.Brand>
                <img
                  alt=""
                  src={CompanyLogo}
                  className="d-inline-block align-top docchoiceImageSize"
                  href={Constants.homeUrl}
                />{" "}
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link eventKey={2} className="navLinkText" href={Constants.homeUrl}>
                    Home
                  </Nav.Link>
                  <NavDropdown title="Products" id="collasible-nav-dropdown">
                    <li></li>
                   
                    <NavDropdown.Item>
                      <Link style={{ textDecoration: "none", color: "black" }} to="/ClinicSolutions">Clinic Solutions</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link style={{ textDecoration: "none", color: "black" }} to="/HospitalSolutions">Hospital Solutions</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link style={{ textDecoration: "none", color: "black" }} to="/PharmacySolutions">Pharmacy Solutions</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link style={{ textDecoration: "none", color: "black" }} to="/LaboratorySolutions">Laboratory Solutions</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link style={{ textDecoration: "none", color: "black" }} to="/FinanceSolutions">Finance Solutions</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link style={{ textDecoration: "none", color: "black" }} to="/ECommerceSolutions">E-Commerce Solutions</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="">
                      <Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Wholesale Solutions</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="">
                      <Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Retail solutions</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item href="">
                      <Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">H R Solutions</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item href="">
                      <Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Artificial Intelligence</Link>
                    </NavDropdown.Item>


                  </NavDropdown>

                  <NavDropdown title="Services" id="collasible-nav-dropdown">
                    <NavDropdown.Item href=""> <Link style={{ textDecoration: "none", color: "black" }} to="/ERPServices">ERP Services</Link></NavDropdown.Item>
                    <NavDropdown.Item href=""> <Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Healthcare Domain Consultation</Link></NavDropdown.Item>

                    <NavDropdown.Item href=""> <Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Implementation of Healthcare solutions</Link></NavDropdown.Item>
                    <NavDropdown.Item href=""><Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Migration Services</Link></NavDropdown.Item>
                    <NavDropdown.Item href=""><Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Support Services</Link></NavDropdown.Item>
                    <NavDropdown.Item href=""><Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Custom Support Services </Link></NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Specialties" id="collasible-nav-dropdown">
                    <NavDropdown.Item href=""> <Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Healthcare Engagement to Experience</Link></NavDropdown.Item>
                    <NavDropdown.Item href=""> <Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Diagnosis to Recovery</Link></NavDropdown.Item>
                    <NavDropdown.Item href=""> <Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Healthcare Hospital to Cash</Link></NavDropdown.Item>
                    <NavDropdown.Item href=""> <Link style={{ textDecoration: "none", color: "black" }} to="/Comingsoon">Artificial Intelligence on Chief Complaints</Link></NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link eventKey={3} className="navLinkText" href="#link">
                    Our Partners
                  </Nav.Link>
                  <NavDropdown title="About Us" id="collasible-nav-dropdown">
                    <li></li>
                    <NavDropdown.Item>
                      <Link style={{ textDecoration: "none", color: "black" }} to="/AboutUs"> About Us</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link style={{ textDecoration: "none", color: "black" }} to="/Ourteam"> Our Team</Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link eventKey={4} >
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar> */}

{/* <Container> */}
        <Navbar
          collapseOnSelect
          expand="lg"
          sticky="top"
          className='navbar_h'
          style={{ background: 'white', 
          boxShadow: '1px 1px 1px rgb(117 131 142/40%), 0 2px 4px rgb(52 60 68/26%)' }}
        >
            <Navbar.Brand href="#home" className='topnavbar'>
              {' '}
              <img
                alt=""
                src={CompanyLogo}
                width='45%'
                className="d-inline-block align-top docchoiceImageSize"
                href={Constants.homeUrl}
              />{' '}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href={Constants.homeUrl}>Home</Nav.Link>
                <NavDropdown title="Products" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="#/ClinicSolutions">
                    Clinic Solutions
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/HospitalSolutions">
                    HospitalSolutions
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/PharmacySolutions">
                    Pharmacy Solutions
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/LaboratorySolutions">
                    Laboratory Solutions
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/FinanceSolutions">
                    Finance Solutions
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/ECommerceSolutions">
                    E-Commerce Solutions
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    Wholesale Solutions
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    Retail solutions
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    H R Solutions
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    Artificial Intelligence
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Services" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="#/ERPServices">
                    ERP Services
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    Healthcare Domain Consultation
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    Implementation of Healthcare solutions
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    Migration Services
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    Support Services
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    Custom Support Services{' '}
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Specialties" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="#/Comingsoon">
                    Healthcare Engagement to Experience
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    Diagnosis to Recovery
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    Healthcare Hospital to Cash
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/Comingsoon">
                    Artificial Intelligence on Chief Complaints
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="About Us" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="#/AboutUs">About Us</NavDropdown.Item>
                  {/* <NavDropdown.Item href="#/Ourteam">Our Team</NavDropdown.Item> */}
                </NavDropdown>
                <Nav>
                  <Nav.Link href="#/ContactUs">Contact Us</Nav.Link>
                </Nav>
                {/* <Nav>
                  <Nav.Link href="#/RequestDemo">IMA</Nav.Link>
                </Nav> */}

                <Nav>
                  <Nav.Link href="#/ima">IMA </Nav.Link>
                </Nav>
              </Nav>
            </Navbar.Collapse>
                    </Navbar>
                    {/* </Container> */}

        {/* </div> */}
      </>
    )
  }
}

export default withRouter(NavBar)
